declare global {
  interface Window {
    jQuery: JQueryStatic;
    $: JQueryStatic;
  }
}

window.jQuery = window.$ = $

import './../smoothscroll'

$(document).on('input', 'textarea', function () {
  const lines = String($(this).val())
  const linesCount = (lines + '\n').match(/\n/g).length

  // textareaだと認識できる高さ以上になった場合に自動で高さを変更（下限をrows=5に設定）
  $(this).attr('rows', (linesCount > 5) ? linesCount : 5)
})

window.addEventListener('DOMContentLoaded', () => {
  // ページが読み込まれれた時に、改行コード分のrowsを設定
  $('textarea').each(function() {
    const lines = $(this).val().toString()
    const linesCount = (lines + '\n').match(/\n/g).length
    $(this).attr('rows', (linesCount > 5) ? linesCount : 5)
  })
})
